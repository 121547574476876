Vue.filter('ellipsis', function (value, length) {
    return value.substring(0, length) + " ...";
});


Vue.filter('date', function (unix_timestamp) {
    if (typeof(unix_timestamp) !== 'number') {
        return unix_timestamp;
    }

    var date = new Date(unix_timestamp * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();

    return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
});

Vue.filter('fromnow', function (val) {
    var date = new Date(val);
    return moment(date).fromNow();
});

Vue.filter('max', function (value, length) {
    if (value.length > length) {
        return value.substring(0, length) + " ...";
    }
    return value;
});

Vue.filter('temp', function (value) {
    return Math.round(value) + '°C';
});